import React, { useContext } from 'react'
// TODO: font change: add
// import Search from '@veneer/core/dist/scripts/search'
import { SearchBoxContainer, SearchBoxWrap } from './styles'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  PrinterListClickedSearchBoxCancelled,
  PrinterListClickedSearchBoxStarted,
  publishEvent
} from '../../utils/analytics'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const SearchTableData = (props) => {
  const { t } = useI18n()
  const { searchVal, setSearchVal, handleSearch, handleClear } = props
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Search } = VeneerCore.core

  return (
    <SearchBoxContainer data-testid="search-box-section">
      <SearchBoxWrap>
        <form onSubmit={handleSearch} className="custom-search-box">
          <Search
            data-testid="search-box"
            id="controlled-search"
            label={t(
              'smbDevices.searchBox.label',
              'Search by printer name or model'
            )}
            value={searchVal}
            onChange={setSearchVal}
            onClear={() => {
              handleClear()
              publishEvent(PrinterListClickedSearchBoxCancelled)
            }}
            onFocus={() => publishEvent(PrinterListClickedSearchBoxStarted)}
          />
        </form>
      </SearchBoxWrap>
    </SearchBoxContainer>
  )
}

export default SearchTableData
