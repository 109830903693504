import { useState, useEffect, useContext } from 'react'
// TODO: font change: add
// import { useToast } from '@veneer/core'
import { getToastErrorStrings } from '../strings/Errors/index'
import {
  ToastIDs,
  ToastTypes,
  DeviceConfigurationsId,
  RemediationResults
} from '../static/consts'
import GeneralContext from '../contexts/GeneralContext'
import { useParams } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import { pickVeneerVersion } from '../font'

export const useRemediationStatus = () => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { useToast } = VeneerCore.core

  const { t } = useI18n()
  const { devID } = useParams()
  const { addToast } = useToast()
  const { remediateComplete } = useContext(GeneralContext)
  const [remediationState, setRemediationState] = useState('')
  const [remediationResult, setRemediationResult] = useState('')
  const [
    remediationConfigAutoFWUpdate,
    setRemediationConfigAutoFWUpdate
  ] = useState({})
  const [
    remediationConfigAdminPasswordSet,
    setRemediationConfigAdminPasswordSet
  ] = useState({})
  const [remediationConfigSNMPV1V2, setRemediationConfigSNMPV1V2] = useState({})
  const [remediationConfigSNMPV3, setRemediationConfigSNMPV3] = useState({})

  const updateRemediationStates = (data) => {
    const remediation = data || {}
    setRemediationState(remediation.remediationstate || '')
    setRemediationResult(remediation.remediationresult || '')
    const setConfig = (config, stateSetter) => {
      const theConfig =
        config && config.remediationdata ? config.remediationdata : {}
      const newConfig: any = {}
      newConfig.remediationState = theConfig.remediationstate || ''
      newConfig.remediationResult = theConfig.remediationresult || ''
      newConfig.remediationResultReason =
        theConfig.remediationresult_reason || ''
      newConfig.isLoading = newConfig.remediationState === 'inprogress'
      newConfig.isComplete = newConfig.remediationState === 'completed'
      stateSetter(newConfig)
    }

    const details = remediation.remediationstatus_detailed || []
    details.forEach((config) => {
      const theConfig = config || {}
      switch (theConfig.configItem) {
        case DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE:
          setConfig(config, setRemediationConfigAutoFWUpdate)
          break
        case DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET:
          setConfig(config, setRemediationConfigAdminPasswordSet)
          break
        case DeviceConfigurationsId.CONFIG_SNMP_V1V2:
          setConfig(config, setRemediationConfigSNMPV1V2)
          break
        case DeviceConfigurationsId.CONFIG_SNMP_V3:
          setConfig(config, setRemediationConfigSNMPV3)
          break
        default:
          break
      }
    })
  }

  useEffect(() => {
    remediateComplete.remediateData &&
      updateRemediationStates(remediateComplete.remediateData)
  }, [remediateComplete])

  useEffect(() => {
    if (remediationResult === RemediationResults.ERROR) {
      const toastData: any = {
        id: ToastIDs.setRemediationCheck.NEGATIVE + devID,
        type: ToastTypes.NEGATIVE,
        ...getToastErrorStrings({ errorCode: 'RemediationError' }, t)
      }
      addToast(toastData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remediationResult])

  return {
    remediationState,
    remediationResult,
    remediationConfigAutoFWUpdate,
    remediationConfigAdminPasswordSet,
    remediationConfigSNMPV1V2,
    remediationConfigSNMPV3
  }
}
